@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkoutItemsContainer {
  padding: 2.5rem 10rem;
  font-size: 2.5rem;
  gap: 2rem;
}

.checkout {
  &__nameContainer {
    display: grid;
    grid-template-columns: auto, auto;
    grid-template-rows: auto;
    justify-content: left;
    align-items: center;
    text-align: left;
  }
  &__photo {
    max-width: 5rem;
    object-fit: cover;
    margin-right: 2rem;
    grid-column: 1/2;
    grid-row: 1/3;
  }
  &__itemName {
    grid-column: 2/3;
    align-self: center;
  }

  &__bundleItems {
    grid-column: 2/3;
    align-content: center;
    font-size: 1.2rem;
    font-style: italic;
  }
  &__quantity {
    font-size: 2.5rem;
    gap: 2rem;
    @include flexCenter;

    .arrow {
      cursor: pointer;
      font-weight: bold;
      background-color: rgb(178, 245, 178);
      padding: 0.1rem;
    }
  }

  &__price {
    @include flexCenter;
  }
  &__remove {
    cursor: pointer;
    @include flexCenter;
  }
}
