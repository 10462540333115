.button-container {
  height: 5rem;
  width: fit-content;
  padding: 2rem;
  font-size: 2rem;
  background-color: rgb(8, 68, 46);
  box-shadow: 0.2rem 0.2rem 0.3rem rgb(1, 7, 5);
  // z-index: 15;

  color: white;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1rem;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5rem;
  margin: 1rem;
  &:hover {
    background-color: white;
    color: rgb(8, 68, 46);
    box-shadow: 0.2rem 0.2rem 0.3rem inset rgb(8, 68, 46);
  }

  &.inverted {
    background-color: white;
    color: rgb(8, 68, 46);
    border: 1ps solid rgb(8, 68, 46);
  }

  &.smaller {
    font-size: 1.6rem;
  }

  &.addToCart {
    font-size: 1.4rem;
    padding: 0rem 1rem;
    border-radius: 1rem;
    height: 3rem;
    width: 100%;
    display: inline-block;
    // margin: auto;
  }

  &.smallBack {
    font-size: 1.8rem;
    padding: 0.5rem 1rem;
    margin: 0.1rem;
    border-radius: 2rem;
    height: auto;
    @media (max-width: 57em) {
      font-size: 1.8rem;
    }
  }

  &.sendOrder {
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    margin: 0.1rem;
    border-radius: 2rem;
    height: auto;
    align-self: center;
    width: 100%;
    justify-content: center;
  }

  &.finalizeSubmit {
    font-size: 2rem;
    padding: 0.5rem 1rem;
    margin-top: 2rem;
    border-radius: 2rem;
    height: auto;
  }
}
