.sectionHero {
  width: 100%;
  height: 115vh;
  z-index: -1;
  margin: auto;

  display: grid;
  grid-template-rows: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  position: relative;
}
.kuponoLogo {
  height: auto;
  width: 25vh;
  padding: 3rem;
  position: sticky;
  top: 40%;
  margin: auto;
}

.heroText {
  // background-image: url("/images/hero.jpg");
  // background-position: center;
  // background-repeat: no-repeat;
  // height: 75vh;
  // width: 100%;
  text-align: center;
  color: white;
  padding-top: 15rem;
  padding-bottom: 15rem;
  justify-self: center;

  position: absolute;
  top: 0;
  left: 0;
}

.heroText h1 {
  font-size: 10rem;
  text-shadow: #333 0.1rem 0rem 0.5rem;
  letter-spacing: 1rem;
}

.heroTextTwo {
  font-size: 4rem;
  font-style: italic;
  text-shadow: #333 0.1rem 0rem 0.5rem;

  padding: 8rem 15rem;
  margin: auto;
}

.heroTextBelow {
  color: #222;
  font-size: 2rem;
  padding: 0rem 15rem;
  margin: auto;
  height: 25vh;
  width: 100%;
  text-align: center;
  grid-row: 3/4;

  animation: fadeIn 7s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
