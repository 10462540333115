.cartItemContainer {
  width: 100%;
  display: flex;
  height: 8rem;
  margin-bottom: 1.5rem;
  align-items: center;

  .itemDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0.1rem;
    font-size: 1.4rem;
  }

  img {
    max-width: 8rem;
    object-fit: cover;
    transform: scale(75%);
  }
}
