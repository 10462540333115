.bundlesContainer {
  justify-content: center;
  gap: 5rem;
  max-width: 150rem;
  height: 50rem;
  // height: 100%;
  margin: auto;
  padding: 5rem;
  z-index: 1;
}

.bundle {
  display: flex;
  max-width: 30rem;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  justify-content: space-evenly;
  text-align: center;
  box-shadow: 0.2rem 0.5rem 0.7rem #333;
  border-radius: 2rem;
  overflow: hidden;
  position: relative;
  background-color: rgba(255, 255, 255, 0.774);
  font-weight: bold;

  &__name {
    font-size: 2.2rem;
    text-transform: uppercase;
  }
  &__cost {
    padding: 2rem;
    font-size: 3.5rem;
    background-color: rgba(255, 255, 255, 0.589);
    border-radius: 1rem;
  }
  &__text {
    padding: 1rem;
    font-size: 2rem;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 50%;
  }
}

.mainBundles {
  position: relative;
  perspective: 500rem;
  -moz-perspective: 500rem;
  transition: all 0.8s;
  backface-visibility: hidden;

  .bundle__front {
    width: 30rem;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  .bundle__back {
    width: 30rem;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;

    transform: rotateY(180deg);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    box-shadow: 0.2rem 0.5rem 0.7rem #333;
    border-radius: 2rem;

    .dropDownListText {
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: bold;
      align-self: center;
    }
    .dropDownListBox {
      display: flex;
      flex-direction: column;
      margin: auto;
    }
  }

  .rotateFront {
    transform: rotateY(180deg);
  }

  .rotateBack {
    transform: rotateY(0);
  }
}
.gridSpecial {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  margin: 0 auto;
}
.howContainer {
  button {
    margin: 0 auto;
  }
}
