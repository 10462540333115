.whoBackgroundBox {
  position: relative;
  padding: 10rem;
  transform: translateY(15rem);

  &::after {
    content: "";
    width: 30%;
    height: 100%;
    background-color: rgb(8, 68, 46);
    // opacity: 50%;
    position: absolute;
    top: 3%;
    right: 27%;
    z-index: -2;
    border-radius: 100%;
  }
}

.whoBox {
  margin: auto;
  max-width: 90rem;
  overflow: hidden;
  border-radius: 5rem;
  box-shadow: 0.5rem 0.7rem 1rem #222;
  margin-bottom: 2rem;
  background-color: white;
}

.whoTextBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(248, 248, 248);

  transition: ease 1s;
}

.whoText {
  text-align: center;
  padding: 0rem 5rem 5rem 5rem;
}
.whoPhoto {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
