.background-box {
  height: 50vh;
  width: 100%;
  top: 0;
  z-index: -1;
  position: sticky;
}

.howContainer {
  // max-width: 150rem;
  max-width: 100%;
  margin: auto;
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 10rem;
  margin-top: 10rem;
  z-index: 50;
  background-color: white;
  padding: 0rem 5rem 0rem 5rem;
}

.howUnit {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 25rem;
  height: 20rem;
  font-weight: bold;
}

.how {
  &__options {
    margin-bottom: 1rem;
  }
}

.makeYourSelection {
  font-weight: bold;
  margin-top: 5rem;

  .or {
    padding: 1rem;
    text-transform: uppercase;
    font-size: 2rem;
  }
}

.bundle {
  &__info {
    margin-top: 5rem;
  }
}

.pickup {
  &__info {
    gap: 5rem;
  }
}

.pickup__location {
  font-weight: bold;
  background-color: rgb(8, 68, 46);
  color: white;
  padding: 1rem;
}

.pickup__sunday {
  .pickup__location {
    margin-top: 2rem;
    background-color: rgb(12, 104, 70);
    display: inline-block;
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.notesContainer {
  padding: 5rem 0rem;
  margin-top: 5rem;
  border-top: 0.1rem solid black;
}

.notes {
  &__grown {
    text-transform: uppercase;
    font-weight: bold;
  }
  &__containers {
    font-size: 1.6rem;
  }
}
