nav {
  width: 100%;
  height: fit-content;
  position: absolute;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.781);
  top: 0;
  left: 0;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: space-between;
  position: fixed;
  align-items: center;

  .social-media {
    display: flex;
    gap: 2rem;
  }
  .nav-icons {
    width: 5rem;
    background-color: white;
    box-shadow: 0.2rem 0.2rem 0.3rem black;
    border-radius: 50%;
    padding: 0.4rem;
    z-index: 500;
  }
}

.nav-icon__Container {
  position: relative;
}
.nav-icon__Bubble {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  font-size: 1.4rem;
  background-color: white;
  padding: 0.1rem 0.2rem;
  border-radius: 20%;
}
.menu {
  display: flex;
  gap: 2rem;
  z-index: 20;
  cursor: pointer;
}
