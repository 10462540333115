//MAX WIDTH 592px
@media (max-width: 37em) {
  body {
    .comingSoonBox {
      max-height: 50rem;
    }
    .tempHead {
      padding: 1rem;
      font-size: 3rem;
    }
  }
}
