.missionBackgroundBox {
  position: relative;
  padding: 5rem;
  transform: translateY(15rem);

  &::after {
    content: "";
    width: 18%;
    height: 100%;
    background-color: rgb(8, 68, 46);
    position: absolute;
    top: 0;
    left: 28%;
    z-index: -1;
    border-radius: 100%;
  }
}

.missionBox {
  display: grid;
  grid-template-columns: 1fr 3fr;
  max-width: 100rem;
  margin: auto;
  border-radius: 2rem;
  box-shadow: 0.5rem 0.7rem 1rem #222;
  overflow: hidden;
}

.missionTextBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(248, 248, 248);
  // transition: ease 1s;
}
.missionText {
  text-align: center;
  padding: 0rem 5rem 5rem 5rem;
}
.missionPhoto {
  // width: 30rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
