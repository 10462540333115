.products-photo-bkg {
  width: 100%;
  height: 100%;
  object-fit: cover;

  filter: brightness(70%);
  z-index: -1;
}

.products-box {
  width: 100%;
  background-color: white;
  text-align: center;
  padding-bottom: 10rem;
  z-index: 1;
  margin: auto;

  .fixSpanGrid {
    grid-column: 1/-1;
    padding: 5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.products-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 1.8rem;

  .products-miniphoto {
    max-width: 20rem;
    height: 30rem;
    object-fit: cover;
    // object-fit: contain;
    box-shadow: 0.5rem 0.7rem 1rem #333;

    &:hover {
      scale: 105%;
      transition: 0.3s ease;
    }
  }
  .number {
    font-weight: bold;
    background-color: rgba(94, 143, 106, 0.74);
    padding: 1rem;
    color: rgb(4, 39, 26);
    border-radius: 100%;
  }

  .desc {
    padding: 2rem;
  }
}
