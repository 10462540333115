.sectionHeading {
  font-size: 4.8rem;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.5rem;
  padding: 5rem;
}

.mediumHeader {
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
  padding: 4rem;
}

.miniHeader {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  padding: 2.5rem;
}

a:link,
a:active {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:visited {
  cursor: pointer;
  color: inherit;
}

.grid2col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid4col {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.grid5col2row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto 1fr;
}

.unhide {
  visibility: visible;
  opacity: 1;
  transform: none;
}

.shiftUp {
  transition: transform 1s;
  transform: translateY(0rem);
}
