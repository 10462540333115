.orderFormContainer {
  padding-top: 10rem;
  padding-bottom: 5rem;
  text-align: center;

  background-image: url("../../images/finalizePhoto.jpg");
  background-position: center;
  background-size: cover;

  h1 {
    font-size: 5rem;
    padding: 2rem;
    color: black;
    background-color: rgba(255, 255, 255, 0.801);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.regularInput {
  font-size: 2rem;
}

form {
  input {
    padding: 0.5rem;
    font-family: "rubik";
    margin: 1rem;
    font-size: 2rem;
    color: black;
  }
}

.submitInstructions {
  font-size: 1.5rem;
  padding: 2rem;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.801);
  font-weight: bold;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.orderSent {
  font-size: 1.8rem;
  font-weight: bold;
  background-color: rgba(60, 255, 0, 0.452);
  padding: 0.5rem 0rem;
  width: 100%;
}

.displayNone {
  display: none;
}
