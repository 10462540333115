//////////SHOP PAGE//////////
.shopContainer {
  padding-top: 5rem;
}

//////////FINALIZE ORDER PAGE//////////

.finalizeOrderContainer {
  text-align: center;
  padding: 0rem 5rem;
  max-width: 180rem;
  margin: auto;
  .grid4col {
    grid-template-columns: 3fr 1fr 1fr auto;
    gap: 1rem;
    margin-right: 0.5rem;
  }
  h1 {
    font-size: 5rem;
  }
}
.subHeaderContainer {
  padding: 5rem 10rem 2.5rem 10rem;
  font-size: 2.8rem;
  justify-content: center;
  border-bottom: 0.1rem solid black;
  gap: 2rem;
  text-transform: uppercase;
  font-weight: bold;
}

.totalContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 5rem 10rem;
  border-top: 0.1rem solid black;

  .total {
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: bold;
  }
  .tax {
    font-size: 1.5rem;
  }
}

//////////COMING SOON PAGE//////////

.comingSoonBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 110rem;
  height: 70rem;
  margin: 6rem auto;

  text-align: center;
  background-image: url("../images/recipes.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10%;
}

.tempHead {
  font-size: 4.5rem;
  font-weight: bold;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.651);
  padding: 2rem;
  border-radius: 1rem;
}
